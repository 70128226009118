<template>
  <div>
    <v-card outlined>
      <div class="d-flex">
        <v-icon class="v-card--stats--icon grey--text text--darken-1 ml-3 mb-1">
          {{ icon }}
        </v-icon>
        <div class="ml-4 my-2">
          <h3 class="subtitle-1 grey--text text--darken-1 font-weight-medium">
            {{ title }}
          </h3>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="d-flex grow align-center justify-center mb-3"
      >
        <EvercamLoadingAnimation :verify-theme-mode="true" size="SixXl" />
      </div>
      <v-data-table
        v-else
        :headers="headers"
        :items="items"
        hide-default-footer
        class="custom-data-table"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "CustomTable",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    icon: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    provider: {
      type: Function,
      default: async () => {
        return {
          total: 0,
          data: [],
        }
      },
    },
    providerParams: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true
        let params = {
          sort: "created_at|desc",
          limit: 5,
          page: 1,
          ...this.providerParams,
        }
        let { items } = await this.provider({ params })
        this.items = items
      } catch (error) {
        this.$notifications.error({
          text: `Could not load ${this.title} data!`,
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.v-card--stats--icon {
  font-size: 15px !important;
}
</style>
